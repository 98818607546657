import React, { useState } from 'react'
import { bool, func, shape, string } from 'prop-types'
import { compose } from 'redux'
import { Form as FinalForm } from 'react-final-form'
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl'
import classNames from 'classnames'
import config from '../../config'
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types'
import * as validators from '../../util/validators'
import { formatMoney } from '../../util/currency'
import { types as sdkTypes } from '../../util/sdkLoader'
import { Button, Form, FieldCurrencyInput, Alert } from '../../components'
import css from './EditListingPricingForm.module.css'
import FieldSelect from '../../components/FieldSelect/FieldSelect'

const { Money } = sdkTypes

export const EditListingPricingFormComponent = (props) => (
  <FinalForm
    {...props}
    render={(formRenderProps) => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors
      } = formRenderProps

      const unitType = config.bookingUnitType
      const isNightly = unitType === LINE_ITEM_NIGHT
      const isDaily = unitType === LINE_ITEM_DAY

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit'

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey
      })

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder'
      })

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired'
        })
      )

      const [currencySelected, setCurrencySelected] = useState(props?.initialValues?.price?.currency || config.currency)

      const minPrice = new Money(config.listingMinimumPriceSubUnits, currencySelected)
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow'
          },
          {
            minPrice: formatMoney(intl, minPrice)
          }
        ),
        config.listingMinimumPriceSubUnits
      )
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired

      const classes = classNames(css.root, className)
      const submitReady = (updated && pristine) || ready
      const submitInProgress = updateInProgress
      const submitDisabled = invalid || disabled || submitInProgress
      const { updateListingError, showListingsError } = fetchErrors || {}

      console.log("currencySelected", currencySelected)

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          <Alert type="secondary">
            <FormattedMessage id="EditListingPricingForm.guide"></FormattedMessage>
          </Alert>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <div>
            <div>{pricePerUnitMessage}</div>
            <div style={{ display: 'flex', gap: 20 }}>
              <FieldSelect
                className={css.currencySelector}
                name="currency"
                id="currency"
                defaultValue={currencySelected}
                onChange={(value) => setCurrencySelected(value)}>
                <option value="DKK">DKK</option>
                <option value="SEK">SEK</option>
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
              </FieldSelect>
              <FieldCurrencyInput 
                style={{ flex: 5 }}
                id={`price_${currencySelected}`}
                name="price"
                className={css.priceInput}
                autoFocus
                placeholder={pricePlaceholderMessage}
                currencyConfig={{ ...config.currencyConfig, currency: currencySelected }}
                validate={priceValidators}
              />
            </div>
            <span className={css.feeInfo}>
              <FormattedMessage id="EditListingPricingForm.commissionFeeNote" />
            </span>
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}>
            {saveActionMsg}
          </Button>
        </Form>
      )
    }}
  />
)

EditListingPricingFormComponent.defaultProps = { fetchErrors: null }

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error
  })
}

export default compose(injectIntl)(EditListingPricingFormComponent)
